import { Routes, Route } from "react-router-dom";
import AllProjects from "./AllProjects/AllProjects";
import Graph from "./Graph/Graph";
import Home from "./Home";
import ProjectModel from "./Project/ProjectModel";
// import ManageProjects from './ManageProjects/ManageProjects';
import ManageSlider from "./ManageSlider/ManageSlider";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="projectModel/:id" element={<ProjectModel />} />
      {/* <Route path="/graph" element={<Graph />} /> */}
      <Route path="/manage" element={<ManageSlider />} />
      <Route path="/" element={<AllProjects />}>
        <Route path=":sector" />
      </Route>
    </Routes>
    </ThemeProvider>

  );
}

export default App;
