import React, { useState } from "react";
import "primeicons/primeicons.css";
import animal from "../../src/assets/animal.png";
import clicked_animal from "../../src/assets/animalwh.svg";
import coffe from "../../src/assets/coffe.png";
import clicked_coffe from "../../src/assets/coffeewh.svg";
import evara from "../../src/assets/evara.png";
import fish from "../../src/assets/fish.png";
import clicked_fish from "../../src/assets/fishwh.svg";
import flower from "../../src/assets/flower.png";
import clicked_flower from "../../src/assets/flowerwh.svg";
import fruits from "../../src/assets/fruits.png";
import clicked_Fruit from "../../src/assets/fruitswh.svg";
import mahsolImage from "../../src/assets/mahsol.png";
import clicked_mahsol from "../../src/assets/mahsolwh.svg";
import bee from "../../src/assets/nahl.png";
import clicked_bee from "../../src/assets/nahlwh.svg";
import reef from "../../src/assets/reef-logo.png";
import Footer from "../Footer/Footer.js";
import Graph from "../Graph/Graph";
import Map from "../Map/Map.js";
import "../SectorPins/FruitMarkers/FruitMarkers.css";
import firebase from "../firebase";
import { useGetSectorProjects } from "../hooks";
import "./SideMenu.css";
import style from "./SideMenu.module.css";

export default function SideMenu() {
  const [noOfFruits, setNoOfFruits] = useState([]);

  const [noOfCoffe, setNoOfCoffe] = useState([]);
  const [noOfFlowers, setNoOfFlowers] = useState([]);

  const [noOfMahsol, setNoOfMahsol] = useState([]);
  const [noOfAnimal, setNoOfAnimal] = useState([]);
  const [noOfBee, setNoOfBee] = useState([]);
  const [noOfFish, setNoOfFish] = useState([]);

  const [flagFruit, setFlagFruit] = useState(true);
  const [flagCoffe, setFlagCoffe] = useState(true);
  const [flagFlower, setFlagFlower] = useState(true);
  const [flagMahsol, setFlagMahsol] = useState(true);
  const [flagAnimal, setFlagAnimal] = useState(true);
  const [flagBee, setFlagBee] = useState(true);
  const [flagFish, setFlagFish] = useState(true);

  const [srcImgFruit, setSrcImgFruit] = useState(fruits);
  const [srcImgCoffe, setSrcImgCoffe] = useState("");
  const [srcImgFlower, setSrcImgFlower] = useState("");
  const [srcImgMahsol, setSrcImgMahsol] = useState("");
  const [srcImgAnimal, setSrcImgAnimal] = useState("");
  const [srcImgBee, setSrcImgBee] = useState("");
  const [srcImgFish, setSrcImgFish] = useState("");

  const [styleClassFruit, setStyleClassFruit] = useState("");
  const [styleClassCoffe, setStyleClassCoffe] = useState("");
  const [styleClassFlower, setStyleClassFlower] = useState("");
  const [styleClassMahsol, setStyleClassMahsol] = useState("");
  const [styleClassAnimal, setStyleClassAnimal] = useState("");
  const [styleClassBee, setStyleClassBee] = useState("");
  const [styleClassFish, setStyleClassFish] = useState("");

  const [fruitMarkersDiv, setFruitMarkersDiv] = useState(false);
  const [coffeMarkersDiv, setCoffeMarkersDiv] = useState(false);
  const [flowerMarkersDiv, setFlowerMarkersDiv] = useState(false);
  const [mahsolMarkersDiv, setMahsolMarkersDiv] = useState(false);
  const [animalMarkersDiv, setAnimalMarkersDiv] = useState(false);
  const [beeMarkersDiv, setBeeMarkersDiv] = useState(false);
  const [fishMarkersDiv, setFishMarkersDiv] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [showCityGraph, setShowCityGraph] = useState(true);

  const fruit = useGetSectorProjects("الفاكهة");
  const coffee = useGetSectorProjects("البن");
  const flowers = useGetSectorProjects("الورد");
  const mahsol = useGetSectorProjects("المحاصيل البعلية");
  const animals = useGetSectorProjects("الثروة الحيوانية");
  const bees = useGetSectorProjects("العسل");
  const fishes = useGetSectorProjects("السمك");

  const getDataForGraph = async (e) => {
    try {
      let sectorName = e.target.getAttribute("sector_name");
      firebase
        .database()
        .ref("projects")
        .on("value", (snap) => {
          let res = snap.val();
          const response = Object.keys(res).map((k) => res[k]);
          switch (sectorName) {
            case "الفاكهة":
              {
                const filteredSector = fruit;

                const clonedObjFruit = {};

                filteredSector.map((item) => {
                  const city = item.city;

                  if (clonedObjFruit[city]) {
                    clonedObjFruit[city]++;
                  } else {
                    clonedObjFruit[city] = 1;
                  }
                });

                setNoOfFruits([clonedObjFruit]);
              }

              break;
            case "البن":
              {
                const filteredSector = response.filter(
                  (item) => item.sectorName === "البن"
                );

                const clonedObjCoffe = {};
                filteredSector.map((item) => {
                  const city = item.city;

                  if (clonedObjCoffe[city]) {
                    clonedObjCoffe[city]++;
                  } else {
                    clonedObjCoffe[city] = 1;
                  }
                });

                setNoOfCoffe([clonedObjCoffe]);
              }
              break;
            case "الورد":
              {
                const filteredSector = response.filter(
                  (item) => item.sectorName === "الورد"
                );

                const clonedObjFlower = {};
                filteredSector.map((item) => {
                  const city = item.city.trim();

                  if (clonedObjFlower[city]) {
                    clonedObjFlower[city]++;
                  } else {
                    clonedObjFlower[city] = 1;
                  }
                });

                setNoOfFlowers([clonedObjFlower]);
              }
              break;
            case "المحاصيل البعلية":
              {
                const filteredSector = response.filter(
                  (item) => item.sectorName === "المحاصيل البعلية"
                );

                const clonedObjMahsol = {};
                filteredSector.map((item) => {
                  const city = item.city.trim();

                  if (clonedObjMahsol[city]) {
                    clonedObjMahsol[city]++;
                  } else {
                    clonedObjMahsol[city] = 1;
                  }
                });

                setNoOfMahsol([clonedObjMahsol]);
              }
              break;
            case "الثروة الحيوانية":
              {
                const filteredSector = response.filter(
                  (item) => item.sectorName === "الثروة الحيوانية"
                );

                const clonedObjAnimal = {};
                filteredSector.map((item) => {
                  const city = item.city.trim();

                  if (clonedObjAnimal[city]) {
                    clonedObjAnimal[city]++;
                  } else {
                    clonedObjAnimal[city] = 1;
                  }
                });

                setNoOfAnimal([clonedObjAnimal]);
              }
              break;
            case "العسل":
              {
                const filteredSector = response.filter(
                  (item) => item.sectorName === "العسل"
                );

                const clonedObjBee = {};
                filteredSector.map((item) => {
                  const city = item.city.trim();

                  if (clonedObjBee[city]) {
                    clonedObjBee[city]++;
                  } else {
                    clonedObjBee[city] = 1;
                  }
                });

                setNoOfBee([clonedObjBee]);
              }
              break;
            case "السمك": {
              const filteredSector = response.filter(
                (item) => item.sectorName === "السمك"
              );

              const clonedObjFish = {};
              filteredSector.map((item) => {
                const city = item.city;

                if (clonedObjFish[city]) {
                  clonedObjFish[city]++;
                } else {
                  clonedObjFish[city] = 1;
                }
              });

              setNoOfFish([clonedObjFish]);
            }
            default:
              console.log("default case!");
          }
        });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handelShowGraph = () => {
    setShowGraph(!showGraph);
  };

  const handleToggleIcon = (e) => {
    // eslint-disable-next-line
    switch (e.target.getAttribute("custom_attribute")) {
      case "fruit":
        {
          if (flagFruit) {
            setSrcImgFruit(clicked_Fruit);
            setStyleClassFruit(style.clicked_fruit_style);
            setFruitMarkersDiv(!fruitMarkersDiv);
            setFlagFruit(!flagFruit);
            getDataForGraph(e);
          } else {
            setSrcImgFruit(fruits);
            setStyleClassFruit(style.icon_section);
            setFruitMarkersDiv(!fruitMarkersDiv);
            setFlagFruit(!flagFruit);
          }
        }

        break;
      case "coffe":
        {
          if (flagCoffe) {
            setSrcImgCoffe(clicked_coffe);
            setStyleClassCoffe(style.clicked_coffe_style);
            setCoffeMarkersDiv(!coffeMarkersDiv);
            setFlagCoffe(!flagCoffe);
            getDataForGraph(e);
          } else {
            setSrcImgCoffe(coffe);
            setStyleClassCoffe(style.icon_section);
            setCoffeMarkersDiv(!coffeMarkersDiv);
            setFlagCoffe(!flagCoffe);
          }
        }
        break;
      case "flower":
        {
          if (flagFlower) {
            setSrcImgFlower(clicked_flower);
            setStyleClassFlower(style.clicked_flower_style);
            setFlowerMarkersDiv(!flowerMarkersDiv);
            setFlagFlower(!flagFlower);
            getDataForGraph(e);
          } else {
            setSrcImgFlower(flower);
            setStyleClassFlower(style.icon_section);
            setFlowerMarkersDiv(!flowerMarkersDiv);
            setFlagFlower(!flagFlower);
          }
        }
        break;

      case "mahsol":
        {
          if (flagMahsol) {
            setSrcImgMahsol(clicked_mahsol);
            setStyleClassMahsol(style.clicked_mahsol_style);
            setMahsolMarkersDiv(!mahsolMarkersDiv);
            setFlagMahsol(!flagMahsol);
            getDataForGraph(e);
          } else {
            setSrcImgMahsol(mahsolImage);
            setStyleClassMahsol(style.icon_section);
            setMahsolMarkersDiv(!mahsolMarkersDiv);
            setFlagMahsol(!flagMahsol);
          }
        }
        break;

      case "animal":
        {
          if (flagAnimal) {
            setSrcImgAnimal(clicked_animal);
            setStyleClassAnimal(style.clicked_animal_style);
            setAnimalMarkersDiv(!animalMarkersDiv);
            setFlagAnimal(!flagAnimal);
            getDataForGraph(e);
          } else {
            setSrcImgAnimal(animal);
            setStyleClassAnimal(style.icon_section);
            setAnimalMarkersDiv(!animalMarkersDiv);
            setFlagAnimal(!flagAnimal);
          }
        }

        break;

      case "bee":
        {
          if (flagBee) {
            setSrcImgBee(clicked_bee);
            setStyleClassBee(style.clicked_bee_style);
            setBeeMarkersDiv(!beeMarkersDiv);
            setFlagBee(!flagBee);
            getDataForGraph(e);
          } else {
            setSrcImgBee(bee);
            setStyleClassBee(style.icon_section);
            setBeeMarkersDiv(!beeMarkersDiv);
            setFlagBee(!flagBee);
          }
        }
        break;
      case "fish":
        {
          if (flagFish) {
            setSrcImgFish(clicked_fish);
            setStyleClassFish(style.clicked_fish_style);
            setFishMarkersDiv(!fishMarkersDiv);
            setFlagFish(!flagFish);
            getDataForGraph(e);
          } else {
            setSrcImgFish(fish);
            setStyleClassFish(style.icon_section);
            setFishMarkersDiv(!fishMarkersDiv);
            setFlagFish(!flagFish);
          }
        }
        break;
      default:
        console.log("default case");
    }
  };

  return (
    <div className="home-container">
      <div className={style.sidemenu}>
        <section>
          <div className={style.logo}>
            <img src={reef} alt="reef" />
          </div>
        </section>
        {/* <section
          style={{
            display: "flex",
            justifyContent: "center",
            padding: " 48px 0px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <i
            className={showGraph ? "pi pi-map-marker" : "pi pi-chart-bar"}
            style={{
              fontSize: "35px",
              color: "white",
              backgroundColor: "#136F3B",
              top: "13%",
              position: "absolute",
              cursor: "pointer",
              width: "60px",
              height: "55px",
              paddingRight: "11px",
              paddingTop: "8px",
              borderRadius: "0.4rem",
            }}
            onClick={handelShowGraph}
          ></i>
        </section> */}
        <ul onClick={handleToggleIcon} className={style.sidemenu_ul}>
          <li className={styleClassFruit || fruits} custom_attribute="fruit">
            <img
              src={srcImgFruit || fruits}
              alt="fruit"
              custom_attribute="fruit"
              sector_name="الفاكهة"
            />
            {<p>الفاكهة</p>}
          </li>
          <li className={styleClassCoffe || coffe} custom_attribute="coffe">
            <img
              src={srcImgCoffe || coffe}
              alt="coffe"
              custom_attribute="coffe"
              sector_name="البن"
            />
            <p>البن</p>
          </li>
          <li className={styleClassFlower || flower} custom_attribute="flower">
            <img
              src={srcImgFlower || flower}
              alt="flower"
              custom_attribute="flower"
              sector_name="الورد"
            />
            <p>الورد</p>
          </li>
          <li className={styleClassMahsol} custom_attribute="mahsol">
            <img
              src={srcImgMahsol || mahsolImage}
              alt="mahsol"
              custom_attribute="mahsol"
              sector_name="المحاصيل البعلية"
            />
            <p>المحاصيل البعلية</p>
          </li>
          <li className={styleClassAnimal} custom_attribute="animal">
            <img
              src={srcImgAnimal || animal}
              alt="animal"
              custom_attribute="animal"
              sector_name="الثروة الحيوانية"
            />
            <p>الثروة الحيوانية</p>
          </li>
          <li className={styleClassBee} custom_attribute="bee">
            <img
              src={srcImgBee || bee}
              alt="bee"
              custom_attribute="bee"
              sector_name="العسل"
            />
            <p>العسل</p>
          </li>
          <li className={styleClassFish} custom_attribute="fish">
            <img
              src={srcImgFish || fish}
              alt="fish"
              custom_attribute="fish"
              sector_name="السمك"
            />
            <p>السمك</p>
          </li>
        </ul>
        <section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: "3%",
              padding: "4px",
              width: "58px",
              right: "0px",
            }}
          >
            <img
              style={{ width: "100%", paddingRight: "4px" }}
              src={evara}
              alt="evara_logo"
            />
          </div>
        </section>
        {/* <div className="marker-locations"
          onClick={(e) => { handleShowCard(e) }} >
            ssss
          {showCard && (
            <div
              style={{
                position: "absolute",
                right: "200px",
                top: "40px",
                width: '1600px',
                height: "800px",
              }} >
              <ProjectCard
                h_value={cardData?.h_value}
                p_location={''}
                img_src={cardData?.img_src}
                projectId={cardData?.projectId}
              />
            </div>
          )}
        </div> */}
      </div>
      {!showGraph && (
        <div className="relative z-0 h-screen pins-container">
          <Map
            fruit={fruitMarkersDiv ? fruit : null}
            coffee={coffeMarkersDiv ? coffee : null}
            flowers={flowerMarkersDiv ? flowers : null}
            mahsol={mahsolMarkersDiv ? mahsol : null}
            animals={animalMarkersDiv ? animals : null}
            bees={beeMarkersDiv ? bees : null}
            fishes={fishMarkersDiv ? fishes : null}
          />
          {/* {fruitMarkersDiv && <FruitMarkers />}
          {coffeMarkersDiv && <CoffeMakers />}
          {flowerMarkersDiv && <Flower />}
          {mahsolMarkersDiv && <Mahsol />}
          {animalMarkersDiv && <Animal />}
          {beeMarkersDiv && <Bee />}
          {fishMarkersDiv && <FishMarkers />} */}
          <Footer className="z-[1000]" />
        </div>
      )}
      {showGraph && (
        <div className="graph-container">
          <div className="graph-control-btn">
            <div className="graph-view-options">
              <button
                className={showCityGraph ? "city-btn-clicked" : "city-btn"}
                onClick={() => setShowCityGraph(true)}
              >
                المدينة
              </button>
              <button
                className={!showCityGraph ? "sector-btn-clicked" : "sector-btn"}
                onClick={() => setShowCityGraph(false)}
              >
                القطاع
              </button>
            </div>
            {/* <div className="graph-price-btn">
          <div className="price-btn">
          <button className="city-price-btn">
            city price
          </button>
          <button className="sector-price-btn">
            sector price
          </button>
          </div>
        </div> */}
          </div>
          <Graph
            fruit={fruitMarkersDiv}
            coffe={coffeMarkersDiv}
            flower={flowerMarkersDiv}
            mahsol={mahsolMarkersDiv}
            animal={animalMarkersDiv}
            bee={beeMarkersDiv}
            fish={fishMarkersDiv}
            graphCityStatus={showCityGraph}
            noOfFruits={noOfFruits}
            noOfCoffe={noOfCoffe}
            noOfFlowers={noOfFlowers}
            noOfMahsol={noOfMahsol}
            noOfAnimal={noOfAnimal}
            noOfBee={noOfBee}
            noOfFish={noOfFish}
          />
          <Footer />
        </div>
      )}
    </div>
  );
}
