import React from "react";
import evara from "../assets/png/Recovered-1.png";
import reef from "../assets/png/slogo.png";
import { Link } from "react-router-dom";
import "./AllProjectsSideMenu.css";

const AllProjectsSideMenu = () => {
  return (
    <div className=" bg-[#136f3b] flex flex-col justify-center items-center w-[70px] min-h-[100vh] relative">
      <div className="absolute flex flex-col top-4">
        <img
          className="bg-white p-[4px] rounded-md mb-4 w-[3.1rem] h-[3.1rem] border-[1px] border-[#dfad37]"
          src={reef}
          alt="reef"
        />
        <Link to="/">
          <button className="home-btn w-[3.1rem] h-[3.1rem] border-[1px] border-white rounded-md mb-4 cursor-pointer" />
        </Link>
      </div>
      <img className="absolute w-[55px]  bottom-4" src={evara} alt="evara" />
    </div>
  );
};

export default AllProjectsSideMenu;
