import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import green_marker from "../assets/marker-green.png";
import dark_marker from "../assets/marker-dark.svg";
import arrow_left from "../assets/arrow-left.svg";
import employeesIcon from "../assets/project card/employees.png";
import capacityIcon from "../assets/project card/production capacity.png";
import areaIcon from "../assets/project card/project area.png";
import locationIcon from "../assets/project card/project location.png";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

export default function ProjectCard({
  id,
  title,
  city,
  image,
  employees,
  status,
  area,
  capacity,
}) {
  const formNumbers = (oldNumber) => {
    const suffixes = ["H", "K", "M", "Bn"];
const oldNumberToString = oldNumber.toString() 
    if (oldNumberToString.length<6) {
      return `(m2) ${oldNumber.toLocaleString('en', {useGrouping:true})}`
      
    } else {
      let newTotal = oldNumber;
  const orderOfMagnitude = Math.min(
    Math.floor(Math.log10(newTotal) / 3),
    suffixes.length - 1
  );
  let newNumberFormatted =
  (newTotal / Math.pow(1000, orderOfMagnitude)).toFixed(1) +
  suffixes[orderOfMagnitude];
  return `${newNumberFormatted} (m2)`;
}




//     let newNumberFormatted =
//       (newTotal / Math.pow(1000, orderOfMagnitude)).toFixed(1) +
//       suffixes[orderOfMagnitude];
// let randomNum = 2336672829929
//     console.log(randomNum.toLocaleString('en', {useGrouping:true}), "///");
//     console.log(newNumberFormatted);
    

//     console.log(suffixes[orderOfMagnitude]);

//     return `${newNumberFormatted} (m2)`;
  };
  return (
    <section className="item border-[1px] border-[#136f3b] rounded-lg overflow-hidden flex items-center  h-[11.3rem] w-[30rem]  gap-[4px]">
      <img src={image} className="w-48 h-full rounded-r-lg" alt={title} />
      <aside className="flex flex-col items-start justify-between w-[58%] h-full p-[10px]">
        <span className="font-bold text-l">
          {title}
          {/* <span className="mr-2">
            {status && (
              <Tooltip title={status} arrow placement="left">
                {status === "منتظم" ? (
                  <CircleIcon
                    style={{
                      fontSize: "15px",
                      color: "#136f3b",
                      border: "1px #136f3b solid",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                  />
                ) : (
                  <CircleIcon
                    style={{
                      fontSize: "15px",
                      color: "#b32f2f",
                      border: "1px #b32f2f solid",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                  />
                )}
              </Tooltip>
            )}
          </span> */}
        </span>{" "}
        <div className="flex flex-col justify-between w-full !gap-y-2">
          <div className="flex w-full justify-between ">
            <div className="w-full">
              <Tooltip
                className="p-0 m-0"
                title={`عدد العمالة: ${
                  employees ? formNumbers(employees) : "__"
                }`}
                arrow
                placement="top"
                style={{ margin: 0, padding: 0 }}
              >
                <div className="flex items-center justify-start">
                  {/* <span className="text-base text-gray-400 ">عدد العمالة:</span> */}
                  <span className="text-base text-gray-400 ">
                    <img src={employeesIcon} alt="employees" className="h-7" />
                  </span>
                  {employees ? (
                    <span className="text-base whitespace-nowrap overflow-hidden overflow-ellipsis w-[70%] cursor-pointer">
                      : {formNumbers(employees)}
                    </span>
                  ) : (
                    <span className="text-base"> : __</span>
                  )}
                </div>
              </Tooltip>
            </div>
            <div className="w-full">
              <Tooltip
                className=""
                title={`مساحة المشروع: ${area ? formNumbers(area): "__"}`}
                arrow
                placement="top"
              >
                <div className="flex items-center justify-start">
                  {/* <span className="text-base text-gray-400">مساحة المشروع:</span> */}
                  <span className="text-base text-gray-400 ">
                    <img src={areaIcon} alt="employees" className="h-6" />
                  </span>
                  {area ? (
                    <span className="text-base whitespace-nowrap overflow-hidden overflow-ellipsis w-[70%] cursor-pointer">
                      : {formNumbers(area)}
                    </span>
                  ) : (
                    <span className=""> : __</span>
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="flex w-full gap-y-2">
            <div className="w-full">
              <Tooltip
                className=""
                title={`الطاقة الإنتاجية: ${
                  capacity ? formNumbers(capacity) : "__"
                }`}
                arrow
              >
                <div className="flex items-center justify-start">
                  {/* <span className="text-base text-gray-400">الطاقة الإنتاجية:</span> */}
                  <span className="text-base text-gray-400 ">
                    <img src={capacityIcon} alt="capacity" className="h-8" />
                  </span>
                  {capacity ? (
                    <span className="text-base whitespace-nowrap overflow-hidden overflow-ellipsis w-[70%] cursor-pointer">
                      : {formNumbers(capacity)}
                    </span>
                  ) : (
                    <span className=""> : __</span>
                  )}
                </div>
              </Tooltip>
            </div>
            <div className="flex items-center w-full">
              {/* <p className="flex items-center text-gray-400 !mb-0 !mx-0"> */}
              {/* <span>
                <img
                  src={green_marker}
                  alt="marker-icon"
                  className="w-4 h-4 ml-2"
                />
              </span> */}
              <span className="text-base text-gray-400 ">
                <img src={locationIcon} alt="employees" className="h-6" />
              </span>
              <span className="text-[#4F4F4F] text-base font-normal mr-1">
                {city}
              </span>
              {/* </p> */}
            </div>
          </div>
        </div>
        <div className="flex justify-end w-full">
          <Link
            className="bg-[#136f3b] text-l w-[140px] h-[35px] p-[3px] text-center rounded-md flex justify-evenly items-center"
            to={`projectModel/${id}`}
          >
            <img alt=">" src={arrow_left} className="w-5" />
            <input
              type="button"
              value="تفاصيل المشروع"
              className="text-white bg-transparent border-none"
            />
          </Link>
        </div>
      </aside>
    </section>
  );
}
