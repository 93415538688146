import React from "react";
import { useParams } from "react-router-dom";
import AllProjectsSideMenu from "../AllProjectsSidemenu/AllProjectsSideMenu";
import Footer from "../Footer/Footer";
import ProjectCard from "../ProjectCard/ProjectCard";
import { useGetSectorProjects } from "../hooks";

export default function AllProjects() {
  const { sector } = useParams();
  const sectorProjects = useGetSectorProjects(sector);
  const renderedSectorProjects = sectorProjects?.map((project) => {
    return (
      <ProjectCard
        key={project.id}
        id={project.id}
        title={project?.initiatorName}
        city={project?.city}
        image={project?.formImage}
        status={project?.projectStatus}
        area={project?.projectArea}
        employees={project?.employeesNo}
        capacity={project?.productionCapacity}
      />
    );
  });

  return (
    <section className="flex w-full h-full">
      <AllProjectsSideMenu />
      <main
        // style={{ width: "calc(100% - 70px)" }}
        className=" w-[calc(100%-70px)] max-h-[100vh] overflow-y-auto flex-parent"
      >
        <div className="flex flex-wrap items-start justify-start w-full gap-4 pt-8 pr-8 ">

        {renderedSectorProjects}
        </div>
        <Footer />
      </main>
    </section>
  );
}
