import React, { useState } from "react";
import AllProjects from "./AllProjects/AllProjects";
import SideMenu from "./SideMenu/SideMenu";
import "./Home.css";
import FormAddProject from "./FormAddProject/FormAddProject";

function Home() {
  // eslint-disable-next-line
  const [flag, setFlag] = useState(true);
  // eslint-disable-next-line
  const [cardsDdata, setCardsDdata] = useState();


  return (
    <div>
      {flag ? (
        <div>
          <SideMenu />
          {/* <GoogleMap /> */}
        </div>
      ) : (
        <div className="h-full">

          <AllProjects cardsDdata={cardsDdata} />
        </div>
      )}
    </div>
    // <div>
    //   <FormAddProject />
    // </div>
  );
}

export default Home;
