import React from "react";
import ProjectSideMenu from "./ProjectSideMenu/ProjectSideMenu.js";
import "./ProjectModel.css";

export default function ProjectModel() {
  return (
    <div className="project-model-container">
      <ProjectSideMenu />
    </div>
  );
}
