import React from "react";
import { Chart } from "primereact/chart";

function sum(values) {
  return values && values.reduce((acc, current) => acc + current, 0);
}

export default function SectorGraph({
  fruits,
  coffee,
  flowers,
  mahsol,
  animals,
  bees,
  fishes,
}) {
  const data = {
    labels: [
      "الفاكهة ",
      "البن",
      "الورد",
      "المحاصيل البعلية",
      "الثروة الحيوانية",
      "العسل",
      "السمك",
    ],
    datasets: [
      {
        label: "عدد المشاريع",
        backgroundColor: "#DFAD37",
        data: [
          sum(fruits),
          sum(coffee),
          sum(flowers),
          sum(mahsol),
          sum(animals),
          sum(bees),
          sum(fishes),
        ],
        fill: false,
        borderColor: "#92d050",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        // text: "Dashboard",
        font: {
          size: 20,
        },
      },
      legend: {
        position: "top",
      },
    },

    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          // stepSize: 0.5,
        },
      },
    },
  };

  return (
    <div className="chart">
      <Chart
        type="bar"
        data={data}
        options={options}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}
