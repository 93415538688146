import React, { useState } from "react";
import { Chart } from "primereact/chart";
import CityProjectGraph from "./CityProjectGraph/CityProjectGraph";
import "./Graph.css";
import SectorGraph from "./SectorGraph/SectorGraph";

const labels = [
  "الباحة",
  "القصيم",
  "حائل",
  "عسير",
  "الشرقية",
  "جازان",
  "الرياض",
  "الطائف",
  "المدينة المنورة",
  "الجبيل",
  "حفر الباطن",
  "عرعر",
  "القنفذة",
  "شقيق",
  "أملج",
  "خور فرسان",
  "الرايس",
  "القطيف",
  "تبوك",
  "نجران",
  "فيفا (جازان)",
  "الداير (جازان)",
];

function result(labels, input) {
  return input && labels?.map((label) => input[label] || 0);
}

export default function Graph({
  fruit,
  coffe,
  flower,
  mahsol,
  animal,
  bee,
  fish,
  graphCityStatus,
  noOfFruits,
  noOfFlowers,
  noOfMahsol,
  noOfAnimal,
  noOfCoffe,
  noOfBee,
  noOfFish,
}) {
  const fruitNum = fruit && result(labels, noOfFruits[0]);

  const coffeNum = coffe && result(labels, noOfCoffe[0]);

  const flowerNum = flower && result(labels, noOfFlowers[0]);

  const mahsolNum = mahsol && result(labels, noOfMahsol[0]);
  const animalNum = animal && result(labels, noOfAnimal[0]);
  const beeNum = bee && result(labels, noOfBee[0]);
  const fishNum = fish && result(labels, noOfFish[0]);
  const [showProjectCityGraph, setShowProjectCityGraph] = useState(false);

  const data = {
    labels,

    datasets: [
      {
        label: "الفاكهة",
        backgroundColor: "#92d050",
        data: fruitNum,
        fill: false,
        borderColor: "#92d050",
      },
      {
        label: "البن",
        backgroundColor: "#806000",
        data: coffeNum,
        fill: false,
        borderColor: "#565656",
      },
      {
        label: "الورد",
        backgroundColor: "#ff65b2",
        data: flowerNum,
        fill: false,
        borderColor: "#565656",
      },
      {
        label: "المحاصيل البعلية",
        backgroundColor: "#bf8f00",
        data: mahsolNum,
        fill: false,
        borderColor: "#565656",
      },
      {
        label: "الثروة الحيوانية",
        backgroundColor: "#c65911",
        data: animalNum,
        fill: false,
        borderColor: "#565656",
      },
      {
        label: "العسل",
        backgroundColor: "#f2b800",
        data: beeNum,
        fill: false,
        borderColor: "#565656",
      },
      {
        label: "السمك",
        backgroundColor: "#00b0f0",
        data: fishNum,
        fill: false,
        borderColor: "#565656",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        // text: "Dashboard",
        font: {
          size: 16,
        },
      },
      legend: {
        position: "top",
      },
    },
  };

  return (
    <div className="chart-container">
      {graphCityStatus && (
        <div className="chart">
          <div className="graph-icons">
            <button
              className={
                !showProjectCityGraph
                  ? "project-city-btn"
                  : "project-city-btn-clicked"
              }
              onClick={() => setShowProjectCityGraph(!showProjectCityGraph)}
            >
              مشروع / مدينة
            </button>
          </div>

          {showProjectCityGraph === false && (
            <Chart
              type="bar"
              data={data}
              options={options}
              style={{ width: "100%", height: "100%" }}
            />
          )}
          {showProjectCityGraph === true && (
            <CityProjectGraph
              fruit={fruit}
              coffe={coffe}
              flower={flower}
              mahsol={mahsol}
              animal={animal}
              bee={bee}
              fish={fish}
              noOfFruits={noOfFruits}
              noOfCoffe={noOfCoffe}
              noOfFlowers={noOfFlowers}
              noOfMahsol={noOfMahsol}
              noOfAnimal={noOfAnimal}
              noOfBee={noOfBee}
              noOfFish={noOfFish}
            />
          )}
        </div>
      )}
      {!graphCityStatus && (
        <SectorGraph
          fruits={fruitNum}
          coffee={coffeNum}
          flowers={flowerNum}
          mahsol={mahsolNum}
          animals={animalNum}
          bees={beeNum}
          fishes={fishNum}
        />
      )}
    </div>
  );
}
