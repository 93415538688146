import { useEffect, useState } from "react";
import firebase from "../firebase";

export function useGetProjects() {
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    firebase
      .database()
      .ref("projects")
      .on("value", (snapshot) => {
        const lists = snapshot.val();
        setProjects(Object.keys(lists).map((k) => lists[k]));
      });
  }, []);

  return projects;
}
