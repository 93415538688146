import React, { useState, useEffect } from 'react'
// import Carousel from 'react-bootstrap/Carousel';
import Carousel from "react-elastic-carousel";

// import axios from 'axios'
import '../ShowImgs/ShowImgs.css'
import firebase from '../../../firebase'
const ExternalImg = ({selectedProject}) => {
  const externalImageArr = []
  const[data, setData] = useState()

  useEffect(() => {
    firebase
    .database()
    .ref('projects')
    .on('value', snapshot => {
      const lists = snapshot.val();
      const arr = Object.keys(lists).map((k) => lists[k]);
      setData(arr.find(e => e.id === selectedProject.id))
      // addProjects(lists)

      
      // state(lists.find(e => e.id === id));
    })
  // axios
  //   .get(`http://85.208.48.114:5000/project/${selectedProject.id}`)
  //   .then(res => {
  //     setData(res.data)
  //   })
  //   .catch(err => {
  //     console.log(err)
  //   })
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  data && 
  // eslint-disable-next-line array-callback-return
  data?.externalImageEntity?.map((element) => {
    externalImageArr.push(element.path)})
    data  && console.log('IMAGE', externalImageArr)
  return (
    <>
    {
      externalImageArr?.length === 0 ? (
        <div 
        className='project-model-location'
        style={{position: 'absolute', right: '40%', top: '50%', fontSize: '40px', width: '900px', height: '50vh'}}
        //  style={{backgroundImage: `url("${flowerBackground}")`}}
        >
        جاري العمل علي التصميم ...
       </div>
      ) : (
        <div className='show-imgs-container'>
        {/* <Carousel slide={true}>
        {externalImageArr?.map((imgPath, i) => {
          return (
            <Carousel.Item interval={2000} key={i} >
              <img
                className="d-block w-100 show-img"
                src={imgPath}
                alt="First slide"
              />
          </Carousel.Item>
          )
        })}
      </Carousel> */}

<Carousel style={{margin:0}}>
    {externalImageArr?.map((item, i) => (
      <div key={i}>
           <img
                className="show-img"
                src={item}
                alt="First slide"
              />
        </div>
    ))}
  </Carousel> 
    </div>
      )
    }
    </>
   
  )
}

export default ExternalImg