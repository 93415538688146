import React, { useState, useEffect } from 'react'
import axios from 'axios';
// import Carousel from 'react-bootstrap/Carousel';
import Carousel from "react-elastic-carousel";

import './ShowImgs.css'
import firebase from '../../../firebase';
const ShowImgs = ({ selectedProject }) => {
  const imgs_path = [];
  const [projectData, setProjectData] = useState()
 
  const getProjectDetails = async(id) => {
    await firebase
    .database()
    .ref('projects')
    .on('value', snapshot => {
      const lists = snapshot.val();
      const arr = Object.keys(lists).map((k) => lists[k]);

      setProjectData(arr.find(e => e.id == selectedProject.id))
      // addProjects(lists)

      
      // state(lists.find(e => e.id === id));
    })
  }
  useEffect(() => {
    if(selectedProject.id) {
      getProjectDetails(selectedProject.id);
    }
    // axios
    //   .get(`http://85.208.48.114:5000/project/${selectedProject.id}`)
    //   .then(res => {
    //     setProjectData(res.data)
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line array-callback-return
  projectData && projectData?.externalImageEntity?.map((element) => {
      imgs_path.push(element.path)})
    // eslint-disable-next-line array-callback-return
    && projectData?.internalImageEntity?.map((element) => {
      imgs_path.push(element.path)})


      return (
    <>
    {
      imgs_path.length === 0 ? (
        <div 
        className='project-model-location'
        style={{position: 'absolute', right: '40%', top: '50%', fontSize: '40px', width: '900px', height: '50vh'}}
        //  style={{backgroundImage: `url("${flowerBackground}")`}}
        >
          جاري العمل علي التصميم ...
        </div>
      ) : (
        <div className='show-imgs-container'>
        {/* <Carousel pause='hover' slide={true}>
          {imgs_path?.map((img_path, i) => {
            return <Carousel.Item interval={2000} key={i} >
              <img
                className="d-block w-100 show-img"
                src={img_path}
                alt="First slide"
              />
            </Carousel.Item>
          })}
        </Carousel> */}


<Carousel style={{margin:0}}>
    {imgs_path?.map((item, i) => (
      <div key={i}>
           <img
                className="show-img"
                src={item}
                alt="First slide"
              />
        </div>
    ))}
  </Carousel> 
      </div>
      )
    }
   </>
  )

}

export default ShowImgs