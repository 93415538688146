import { createTheme } from '@mui/material/styles';

export  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "10px",
            // backgroundColor: '#2A6938',
            borderRadius:"4px",
            color:"#fff",
            fontFamily:"Cairo",
            padding: "4px 8px", // Adjust this to your needs

          },
          arrow: {
            // color: '#2A6938', // Arrow color should match the tooltip background color
          },
        },
      },
    },
  });