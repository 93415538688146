import React from "react";
import { Chart } from "primereact/chart";
import "../Graph.css";

const CityProjectGraph = ({
  fruit,
  coffe,
  flower,
  mahsol,
  animal,
  bee,
  fish,
  noOfFruits,
  noOfFlowers,
  noOfMahsol,
  noOfAnimal,
  noOfCoffe,
  noOfBee,
  noOfFish,
}) => {
  const labels = [
    "الباحة",
    "القصيم",
    "حائل",
    "عسير",
    "الشرقية",
    "جازان",
    "الرياض",
    "الطائف",
    "المدينة المنورة",
    "الجبيل",
    "حفر الباطن",
    "عرعر",
    "القنفذة",
    "شقيق",
    "أملج",
    "خور فرسان",
    "الرايس",
    "القطيف",
    "تبوك",
    "نجران",
    "فيفا (جازان)",
    "الداير (جازان)",
  ];

  function result(labels, input) {
    return input && labels?.map((label) => input[label] || 0);
  }
  // const fruitNum = fruit && Object.values(noOfFruits[0]);
  const fruitNum = fruit && result(labels, noOfFruits[0]);

  // const coffeNum = coffe && Object.values(noOfCoffe[0]);
  const coffeNum = coffe && result(labels, noOfCoffe[0]);


  // const flowerNum = flower && Object.values(noOfFlowers[0]);
  const flowerNum = flower && result(labels, noOfFlowers[0]);

  // const mahsolNum = mahsol && Object.values(noOfMahsol[0]);
  const mahsolNum = mahsol && result(labels, noOfMahsol[0]);

  // const animalNum = animal && Object.values(noOfAnimal[0]);
  const animalNum = animal && result(labels, noOfAnimal[0]);

  // const beeNum = bee && Object.values(noOfBee[0]);
  const beeNum = bee && result(labels, noOfBee[0]);

  // const fishNum = fish && Object.values(noOfFish[0]);
  const fishNum = fish && result(labels, noOfFish[0]);


  const elbahaProjects =
    (fruitNum[0] || 0) +
    (coffeNum[0] || 0) +
    (flowerNum[0] || 0) +
    (mahsolNum[0] || 0) +
    (animalNum[0] || 0) +
    (beeNum[0] || 0) +
    (fishNum[0] || 0);
  const elkaseemProjects =
    (fruitNum[1] || 0) +
    (coffeNum[1] || 0) +
    (flowerNum[1] || 0) +
    (mahsolNum[1] || 0) +
    (animalNum[1] || 0) +
    (beeNum[1] || 0) +
    (fishNum[1] || 0);
  const haelProjects =
    (fruitNum[2] || 0) +
    (coffeNum[2] || 0) +
    (flowerNum[2] || 0) +
    (mahsolNum[2] || 0) +
    (animalNum[2] || 0) +
    (beeNum[2] || 0) +
    (fishNum[2] || 0);
  const aseerProjects =
    (fruitNum[3] || 0) +
    (coffeNum[3] || 0) +
    (flowerNum[3] || 0) +
    (mahsolNum[3] || 0) +
    (animalNum[3] || 0) +
    (beeNum[3] || 0) +
    (fishNum[3] || 0);
  const elsharkyaProjects =
    (fruitNum[4] || 0) +
    (coffeNum[4] || 0) +
    (flowerNum[4] || 0) +
    (mahsolNum[4] || 0) +
    (animalNum[4] || 0) +
    (beeNum[4] || 0) +
    (fishNum[4] || 0);
  const gazanProjects =
    (fruitNum[5] || 0) +
    (coffeNum[5] || 0) +
    (flowerNum[5] || 0) +
    (mahsolNum[5] || 0) +
    (animalNum[5] || 0) +
    (beeNum[5] || 0) +
    (fishNum[5] || 0);
  const alryadProjects =
    (fruitNum[6] || 0) +
    (coffeNum[6] || 0) +
    (flowerNum[6] || 0) +
    (mahsolNum[6] || 0) +
    (animalNum[6] || 0) +
    (beeNum[6] || 0) +
    (fishNum[6] || 0);
  const eltaefProjects =
    (fruitNum[7] || 0) +
    (coffeNum[7] || 0) +
    (flowerNum[7] || 0) +
    (mahsolNum[7] || 0) +
    (animalNum[7] || 0) +
    (beeNum[7] || 0) +
    (fishNum[7] || 0);
  const elmadenaElmonwraProjects =
    (fruitNum[8] || 0) +
    (coffeNum[8] || 0) +
    (flowerNum[8] || 0) +
    (mahsolNum[8] || 0) +
    (animalNum[8] || 0) +
    (beeNum[8] || 0) +
    (fishNum[8] || 0);
  const elgabeelProjects =
    (fruitNum[9] || 0) +
    (coffeNum[9] || 0) +
    (flowerNum[9] || 0) +
    (mahsolNum[9] || 0) +
    (animalNum[9] || 0) +
    (beeNum[9] || 0) +
    (fishNum[9] || 0);
  const hafrElbatenProjects =
    (fruitNum[10] || 0) +
    (coffeNum[10] || 0) +
    (flowerNum[10] || 0) +
    (mahsolNum[10] || 0) +
    (animalNum[10] || 0) +
    (beeNum[10] || 0) +
    (fishNum[10] || 0);
  const ararProjects =
    (fruitNum[11] || 0) +
    (coffeNum[11] || 0) +
    (flowerNum[11] || 0) +
    (mahsolNum[11] || 0) +
    (animalNum[11] || 0) +
    (beeNum[11] || 0) +
    (fishNum[11] || 0);
  const elkanfazaProjects =
    (fruitNum[12] || 0) +
    (coffeNum[12] || 0) +
    (flowerNum[12] || 0) +
    (mahsolNum[12] || 0) +
    (animalNum[12] || 0) +
    (beeNum[12] || 0) +
    (fishNum[12] || 0);
  const shakeekProjects =
    (fruitNum[13] || 0) +
    (coffeNum[13] || 0) +
    (flowerNum[13] || 0) +
    (mahsolNum[13] || 0) +
    (animalNum[13] || 0) +
    (beeNum[13] || 0) +
    (fishNum[13] || 0);
  const amlagProjects =
    (fruitNum[14] || 0) +
    (coffeNum[14] || 0) +
    (flowerNum[14] || 0) +
    (mahsolNum[14] || 0) +
    (animalNum[14] || 0) +
    (beeNum[14] || 0) +
    (fishNum[14] || 0);
  const khanForsanProjects =
    (fruitNum[15] || 0) +
    (coffeNum[15] || 0) +
    (flowerNum[15] || 0) +
    (mahsolNum[15] || 0) +
    (animalNum[15] || 0) +
    (beeNum[15] || 0) +
    (fishNum[15] || 0);
  const elraysProjects =
    (fruitNum[16] || 0) +
    (coffeNum[16] || 0) +
    (flowerNum[16] || 0) +
    (mahsolNum[16] || 0) +
    (animalNum[16] || 0) +
    (beeNum[16] || 0) +
    (fishNum[16] || 0);
  const elkatefProjects =
    (fruitNum[17] || 0) +
    (coffeNum[17] || 0) +
    (flowerNum[17] || 0) +
    (mahsolNum[17] || 0) +
    (animalNum[17] || 0) +
    (beeNum[17] || 0) +
    (fishNum[17] || 0);
  const tabookProjects =
    (fruitNum[18] || 0) +
    (coffeNum[18] || 0) +
    (flowerNum[18] || 0) +
    (mahsolNum[18] || 0) +
    (animalNum[18] || 0) +
    (beeNum[18] || 0) +
    (fishNum[18] || 0);
  const nagranProjects =
    (fruitNum[19] || 0) +
    (coffeNum[19] || 0) +
    (flowerNum[19] || 0) +
    (mahsolNum[19] || 0) +
    (animalNum[19] || 0) +
    (beeNum[19] || 0) +
    (fishNum[19] || 0);
  const fifaProjects =
    (fruitNum[20] || 0) +
    (coffeNum[20] || 0) +
    (flowerNum[20] || 0) +
    (mahsolNum[20] || 0) +
    (animalNum[20] || 0) +
    (beeNum[20] || 0) +
    (fishNum[20] || 0);
  const eldayerProjects =
    (fruitNum[21] || 0) +
    (coffeNum[21] || 0) +
    (flowerNum[21] || 0) +
    (mahsolNum[21] || 0) +
    (animalNum[21] || 0) +
    (beeNum[21] || 0) +
    (fishNum[21] || 0);


  const data = {

    labels: [
      "الباحة ",
      "القصيم",
      "حائل",
      "عسير",
      "الشرقية",
      "جازان",
      "الرياض",
      "الطائف",
      "المدينة المنورة",
      "الجبيل",
      "حفر الباطن",
      "عرعر",
      "القنفذة",
      "شقيق",
      "أملج",
      "خور فرسان",
      "الرايس",
      "القطيف",
      "تبوك",
      "نجران",
      "فيفا (جازان)",
      "الداير (جازان)",
    ],

    datasets: [
      {
        label: "عدد المشاريع",
        backgroundColor: "#DFAD37",
        data: [
          elbahaProjects,
          elkaseemProjects,
          haelProjects,
          aseerProjects,
          elsharkyaProjects,
          gazanProjects,
          alryadProjects,
          eltaefProjects,
          elmadenaElmonwraProjects,
          elgabeelProjects,
          hafrElbatenProjects,
          ararProjects,
          elkanfazaProjects,
          shakeekProjects,
          amlagProjects,
          khanForsanProjects,
          elraysProjects,
          elkatefProjects,
          tabookProjects,
          nagranProjects,
          fifaProjects,
          eldayerProjects,
        ],
        // [elbahaProjects, elkaseemProjects, haelProjects, aseerProjects, elsharkyaProjects,
        //   gazanProjects, elDayerProjects, fifaProjects, elryadProjects, eltaefProjects, elmadenaElmonwraProjects, elgabeelProjects,
        //   hafrElbatenProjects, ararProjects, elkanfazaProjects, shakeekProjects, amlagProjects,
        //   khanForsanProjects, elraysProjects, elkatefProjects, tabookProjects],
        fill: false,
        borderColor: "#92d050",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "عدد المشاريع في كل مدينة",
        font: {
          size: 16,
        },
      },
      legend: {
        position: "top",
      },
    },
  };

  return (
    <>
      <Chart
        type="bar"
        data={data}
        options={options}
        style={{ width: "100%", height: "100%" }}
      />
    </>
  );
};

export default CityProjectGraph;
