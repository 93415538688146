import React from "react";
import { Link } from "react-router-dom";
import footer from "./Footer.module.css";

const Footer = ({ className }) => {
  return (
    <ul className={footer.filter_buttons + " " + className}>
      <li>
        <Link to={`الفاكهة`}>
          <input type="button" value="الفاكهة" />
        </Link>
      </li>
      <li>
        <Link to={`الورد`}>
          <input type="button" value="الورد" />
        </Link>
      </li>
      <li>
        <Link to={`البن`}>
          <input type="button" value="البن" />
        </Link>
      </li>
      <li>
        <Link to={`العسل`}>
          <input type="button" value="العسل" />
        </Link>
      </li>
      <li>
        <Link to={`المحاصيل البعلية`}>
          <input value="المحاصيل البعلية" type="button" />
        </Link>
      </li>
      <li>
        <Link to={`الثروة الحيوانية`}>
          <input type="button" value="الثروة الحيوانية" />
        </Link>
      </li>
      <li>
        <Link to={`القيمة المضافة`}>
          <input type="button" value="القيمة المضافة" />
        </Link>
      </li>
      <li>
        <Link to={`السمك`}>
          <input type="button" value="السمك" />
        </Link>
      </li>
    </ul>
  );
};

export default Footer;
