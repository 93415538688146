import React from "react";
import { Link } from "react-router-dom";
import dark_marker from "../assets/marker-dark.svg";

const MapCard = ({ project }) => {
  // console.log(project);
  return (
    <div className="flex gap-3">
      <div className="w-[200px] h-[120px]">
        <img
          src={project.formImage}
          alt="project"
          className="w-[190px] h-[120px]"
        />
      </div>
      <div className="w-[130px] flex flex-col justify-between items-start">
        <div className="font-bold text-right">{project.initiatorName}</div>
        <div className="flex items-center justify-start gap-0 text-gray-400">
          <span>
            <img src={dark_marker} alt="marker-icon" className="w-6 h-6 ml-1" />
          </span>
          <span className="text-[#4F4F4F]">{project.city}</span>
        </div>

        <div className="bg-[#136f3b] text-md w-[100px] h-[30px] text-center rounded-md flex justify-evenly items-center">
          {/* <span>
            <img src={arrow_left} alt="left arrow" />
          </span> */}
          <Link to={`projectModel/${project.id}`}>
            <input
              type="button"
              value="تفاصيل المشروع"
              className="text-white bg-transparent border-none"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MapCard;
