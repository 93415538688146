import React from "react";
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import iconFruit from "../assets/marker-green.png";
import iconCoffee from "../assets/marker-brown.png";
import iconFlower from "../assets/marker-pink.png";
import iconMahsol from "../assets/marker-light-brown.png";
import iconAnimal from "../assets/marker-red.png";
import iconBee from "../assets/marker-yellow.png";
import iconFish from "../assets/marker-blue.png";
import ProjectCard from "../ProjectCard/ProjectCard";
import MapCard from "./MapCard";

function createCustomIcon(iconUrl) {
  return new L.Icon({
    iconUrl: iconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
    shadowSize: [41, 41],
  });
}

const iconFruitLeaflet = createCustomIcon(iconFruit);
const iconCoffeeLeaflet = createCustomIcon(iconCoffee);
const iconFlowerLeaflet = createCustomIcon(iconFlower);
const iconMahsolLeaflet = createCustomIcon(iconMahsol);
const iconAnimalLeaflet = createCustomIcon(iconAnimal);
const iconBeeLeaflet = createCustomIcon(iconBee);
const iconFishLeaflet = createCustomIcon(iconFish);

function getRandomLatLng() {
  const latCenter = 21.0;
  const lngCenter = 43.0;

  const latRange = 3.0;
  const lngRange = 4.0;

  const latOffset = (Math.random() * latRange - latRange / 2).toFixed(6);
  const lngOffset = (Math.random() * lngRange - lngRange / 2).toFixed(6);

  const lat = (parseFloat(latCenter) + parseFloat(latOffset)).toFixed(6);
  const lng = (parseFloat(lngCenter) + parseFloat(lngOffset)).toFixed(6);

  return [parseFloat(lat), parseFloat(lng)];
}

function RenderMarkers({ sectors, icon }) {
  if (Array.isArray(sectors)) {
    return sectors.map((project, index) => {
      let { city,lat, lng } = project.location;
      if (!lat || !lng) {
        [lat, lng] = getRandomLatLng();
        // console.log(index,"prooo",project)
      }
      // else {
      //   console.log(index,"lat done",project.city)
      // }

      const offset = 0.04 * index;

      // const offset = 0.25 * index;
      // const offset = Math.random();
      // console.log(offset,"offset")
      // const newLat = parseFloat(lat) ;
      // const newLng = parseFloat(lng) ;
      const newLat = parseFloat(lat) + offset;
      const newLng = parseFloat(lng) + offset;

      // console.log(newLat,"newLat",project.city,newLng,"newLng")

      // console.log(newLat,project.city,project.initiatorName)
      // console.log(newLng,project.city)
      // console.log(project.city)

      return (
        <Marker key={index} position={[newLat, newLng]} icon={icon}>
          {/* <Popup>{project.initiatorName} {project.city}</Popup> */}
          <Popup className="w-33">
            <MapCard project={project}/>
          {/* <ProjectCard
            key={project.id}
            id={project.id}
            title={project?.initiatorName}
            city={project?.city}
            image={project?.formImage}
            status={project?.projectStatus}
            area={project?.projectArea}
            employees={project?.employeesNo}
            capacity={project?.productionCapacity}
          /> */}
          </Popup>
        </Marker>
      );
    });
  }

  return <p>Please select a sector name</p>;
}

export default function Map({
  fruit,
  coffee,
  flowers,
  mahsol,
  animals,
  bees,
  fishes,
}) {
  return (
    <MapContainer
      center={[21.7136, 49.0799]}
      zoom={6}
      className="w-full h-screen"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution=""
      />
      <RenderMarkers sectors={fruit} icon={iconFruitLeaflet} />
      <RenderMarkers sectors={coffee} icon={iconCoffeeLeaflet} />
      <RenderMarkers sectors={flowers} icon={iconFlowerLeaflet} />
      <RenderMarkers sectors={mahsol} icon={iconMahsolLeaflet} />
      <RenderMarkers sectors={animals} icon={iconAnimalLeaflet} />
      <RenderMarkers sectors={bees} icon={iconBeeLeaflet} />
      <RenderMarkers sectors={fishes} icon={iconFishLeaflet} />
    </MapContainer>
  );
}
