import React, { useState, useEffect } from "react";
import "./ProjectMarkerLocations.css";
import firebase from "../../firebase";

const ProjectMarkerLocations = ({ sectorName, projectId, projectName }) => {
  const [fruitDetails, setFruitDetails] = useState();
  const [flowerDetails, setFlowerDetails] = useState();
  const [coffeDetails, setCoffeDetails] = useState();
  const [honeyDetails, setHoneyDetails] = useState();
  const [animalDetails, setAnimalDetails] = useState();
  const [fishDetails, setFishDetails] = useState();
  const [boatDetails, setBoatDetails] = useState();
  const [cropsDetails, setCropsDetails] = useState();
  const [superMarketDetails1, setSuperMarketDetails1] = useState();
  const [superMarketDetails2, setSuperMarketDetails2] = useState();

  const [fruitImg1, setFruitImg1] = useState(false);
  const [fruitImg2, setFruitImg2] = useState(false);
  const [fruitImg3, setFruitImg3] = useState(false);
  const [fruitImg4, setFruitImg4] = useState(false);

  const [flowerImg1, setFlowerImg1] = useState(false);
  const [flowerImg2, setFlowerImg2] = useState(false);
  const [flowerImg3, setFlowerImg3] = useState(false);
  const [flowerImg4, setFlowerImg4] = useState(false);

  const [coffeImg1, setCoffeImg1] = useState(false);
  const [coffeImg2, setCoffeImg2] = useState(false);
  const [coffeImg3, setCoffeImg3] = useState(false);
  const [coffeImg4, setCoffeImg4] = useState(false);

  const [honeyImg1, setHoneyImg1] = useState(false);
  const [honeyImg2, setHoneyImg2] = useState(false);
  const [honeyImg3, setHoneyImg3] = useState(false);
  const [honeyImg4, setHoneyImg4] = useState(false);

  const [animalImg1, setAnimalImg1] = useState(false);
  const [animalImg2, setAnimalImg2] = useState(false);
  const [animalImg3, setAnimalImg3] = useState(false);
  const [animalImg4, setAnimalImg4] = useState(false);

  const [fishImg1, setFishImg1] = useState(false);
  const [fishImg2, setFishImg2] = useState(false);
  const [fishImg3, setFishImg3] = useState(false);
  const [fishImg4, setFishImg4] = useState(false);

  const [boatImg1, setBoatImg1] = useState(false);
  const [boatImg2, setBoatImg2] = useState(false);
  const [boatImg3, setBoatImg3] = useState(false);
  // const [boatImg4, setBoatImg4] = useState(false)

  const [cropsImg1, setCropsImg1] = useState(false);
  const [cropsImg2, setCropsImg2] = useState(false);
  const [cropsImg3, setCropsImg3] = useState(false);
  const [cropsImg4, setCropsImg4] = useState(false);

  const [superMarketOneImg1, setSuperMarketOneImg1] = useState(false);
  const [superMarketOneImg2, setSuperMarketOneImg2] = useState(false);
  const [superMarketOneImg3, setSuperMarketOneImg3] = useState(false);
  const [superMarketOneImg4, setSuperMarketOneImg4] = useState(false);

  const [superMarketTwoImg1, setSuperMarketTwoImg1] = useState(false);
  const [superMarketTwoImg2, setSuperMarketTwoImg2] = useState(false);
  const [superMarketTwoImg3, setSuperMarketTwoImg3] = useState(false);
  const [superMarketTwoImg4, setSuperMarketTwoImg4] = useState(false);

  const getDetails = (sector, id, state) => {
    firebase
      .database()
      .ref(sector)
      .on("value", (res) => {
        let lists = res.val();
        const arr = Object.keys(lists).map((k) => lists[k]);
        state(arr.find((e) => e.id == id));
      });
    //  firebase
    //   .firestore()
    //   .collection(sector)
    //   .onSnapshot(snapshot => {
    //     const lists = snapshot.docs.map(doc => ({
    //       id: doc.id,
    //       ...doc.data(),
    //     }))

    //     state(lists.find(e => e.id === id));
    //   })
  };
  // FRUITS
  useEffect(() => {
    getDetails("الفاكهة", 37, setFruitDetails);
    getDetails("الورد", 55, setFlowerDetails);
    getDetails("البن", 34, setCoffeDetails);
    getDetails("العسل", 24, setHoneyDetails);
    getDetails("الثروة الحيوانية", 67, setAnimalDetails);
    getDetails("السمك", 43, setFishDetails);
    getDetails("السمك", 54, setBoatDetails);
    getDetails("المحاصيل البعلية", 32, setCropsDetails);
    getDetails("القيمة المضافة", 74, setSuperMarketDetails1);
    getDetails("القيمة المضافة", 65, setSuperMarketDetails2);
  }, []);

  const fruitBackground = fruitDetails && fruitDetails?.formImage;
  const flowerBackground = flowerDetails && flowerDetails?.formImage;
  const coffeBackground = coffeDetails && coffeDetails?.formImage;
  const honeyBackground = honeyDetails && honeyDetails?.formImage;
  const animalBackground = animalDetails && animalDetails?.formImage;
  const fishBackground = fishDetails && fishDetails?.formImage;
  const boatBackground = boatDetails && boatDetails?.formImage;
  const cropsBackground = cropsDetails && cropsDetails?.formImage;
  const superMarketBackground1 =
    superMarketDetails1 && superMarketDetails1?.formImage;
  const superMarketBackground2 =
    superMarketDetails2 && superMarketDetails2?.formImage;


  const handleLocations = (sector) => {
    if (sector === "الفاكهة") {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${fruitBackground}")` }}
          >
            <button
              className="marker-fruit-location-1"
              onClick={() => setFruitImg1(true)}
            />
            <button
              className="marker-fruit-location-2"
              onClick={() => setFruitImg2(true)}
            />
            <button
              className="marker-fruit-location-3"
              onClick={() => setFruitImg3(true)}
            />
            <button
              className="marker-fruit-location-4"
              onClick={() => setFruitImg4(true)}
            />
          </div>
        </>
      );
    } else if (sector === "الورد" && projectId === 55) {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${flowerBackground}")` }}
          >
            <button
              className="marker-flower-location-1"
              onClick={() => setFlowerImg1(true)}
            />
            <button
              className="marker-flower-location-2"
              onClick={() => setFlowerImg2(true)}
            />
            <button
              className="marker-flower-location-3"
              onClick={() => setFlowerImg3(true)}
            />
            <button
              className="marker-flower-location-4"
              onClick={() => setFlowerImg4(true)}
            />
          </div>
        </>
      );
    } else if (sector === "الورد" && projectId !== 55) {
      return (
        <>
          <div
            className="project-model-location"
            style={{
              position: "absolute",
              right: "40%",
              top: "50%",
              fontSize: "40px",
              width: "900px",
              height: "50vh",
            }}
            //  style={{backgroundImage: `url("${flowerBackground}")`}}
          >
            جاري عمل نموذج ثلاثي الابعاد ...
          </div>
        </>
      );
    } else if (sector === "البن") {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${coffeBackground}")` }}
          >
            <button
              className="marker-coffe-location-1"
              onClick={() => setCoffeImg1(true)}
            />
            <button
              className="marker-coffe-location-2"
              onClick={() => setCoffeImg2(true)}
            />
            <button
              className="marker-coffe-location-3"
              onClick={() => setCoffeImg3(true)}
            />
            <button
              className="marker-coffe-location-4"
              onClick={() => setCoffeImg4(true)}
            />
          </div>
        </>
      );
    } else if (sector === "العسل") {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${honeyBackground}")` }}
          >
            <button
              className="marker-honey-location-1"
              onClick={() => setHoneyImg1(true)}
            />
            <button
              className="marker-honey-location-2"
              onClick={() => setHoneyImg2(true)}
            />
            <button
              className="marker-honey-location-3"
              onClick={() => setHoneyImg3(true)}
            />
            <button
              className="marker-honey-location-4"
              onClick={() => setHoneyImg4(true)}
            />
          </div>
        </>
      );
    } else if (sector === "الثروة الحيوانية") {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${animalBackground}")` }}
          >
            <button
              className="marker-animal-location-1"
              onClick={() => setAnimalImg1(true)}
            />
            <button
              className="marker-animal-location-2"
              onClick={() => setAnimalImg2(true)}
            />
            <button
              className="marker-animal-location-3"
              onClick={() => setAnimalImg3(true)}
            />
            <button
              className="marker-animal-location-4"
              onClick={() => setAnimalImg4(true)}
            />
          </div>
        </>
      );
    } else if (sector === "السمك" && projectName === "حراج الاسماك ") {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${fishBackground}")` }}
          >
            <button
              className="marker-fish-location-1"
              onClick={() => setFishImg1(true)}
            />
            <button
              className="marker-fish-location-2"
              onClick={() => setFishImg2(true)}
            />
            <button
              className="marker-fish-location-3"
              onClick={() => setFishImg3(true)}
            />
            <button
              className="marker-fish-location-4"
              onClick={() => setFishImg4(true)}
            />
          </div>
        </>
      );
    } else if (
      sector === "السمك" &&
      projectName === "مراكز تطوير وصيانة القوارب"
    ) {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${boatBackground}")` }}
          >
            <button
              className="marker-boat-location-1"
              onClick={() => setBoatImg1(true)}
            />
            <button
              className="marker-boat-location-2"
              onClick={() => setBoatImg2(true)}
            />
            <button
              className="marker-boat-location-3"
              onClick={() => setBoatImg3(true)}
            />
            {/* <button className='marker-boat-location-4' onClick={()=>setBoatImg4(true)}/> */}
          </div>
        </>
      );
    } else if (sector === "المحاصيل البعلية") {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${cropsBackground}")` }}
          >
            <button
              className="marker-crops-location-1"
              onClick={() => setCropsImg1(true)}
            />
            <button
              className="marker-crops-location-2"
              onClick={() => setCropsImg2(true)}
            />
            <button
              className="marker-crops-location-3"
              onClick={() => setCropsImg3(true)}
            />
            <button
              className="marker-crops-location-4"
              onClick={() => setCropsImg4(true)}
            />
          </div>
        </>
      );
    } else if (sector === "القيمة المضافة" && projectId === 74) {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${superMarketBackground1}")` }}
          >
            <button
              className="marker-superMarket1-location-1"
              onClick={() => setSuperMarketOneImg1(true)}
            />
            <button
              className="marker-superMarket1-location-2"
              onClick={() => setSuperMarketOneImg2(true)}
            />
            <button
              className="marker-superMarket1-location-3"
              onClick={() => setSuperMarketOneImg3(true)}
            />
            <button
              className="marker-superMarket1-location-4"
              onClick={() => setSuperMarketOneImg4(true)}
            />
          </div>
        </>
      );
    } else if (sector === "القيمة المضافة" && projectId === 65) {
      return (
        <>
          <div
            className="project-model-location"
            style={{ backgroundImage: `url("${superMarketBackground2}")` }}
          >
            <button
              className="marker-superMarket2-location-1"
              onClick={() => setSuperMarketTwoImg1(true)}
            />
            <button
              className="marker-superMarket2-location-2"
              onClick={() => setSuperMarketTwoImg2(true)}
            />
            <button
              className="marker-superMarket2-location-3"
              onClick={() => setSuperMarketTwoImg3(true)}
            />
            <button
              className="marker-superMarket2-location-4"
              onClick={() => setSuperMarketTwoImg4(true)}
            />
          </div>
        </>
      );
    }
  };

  return (
    <>
      {handleLocations(sectorName)}

      {/* Fruit */}
      {fruitImg1 && fruitDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={fruitDetails?.internalImageEntity[1].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFruitImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {fruitImg2 && fruitDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={fruitDetails?.externalImageEntity[4].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFruitImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {fruitImg3 && fruitDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={fruitDetails?.externalImageEntity[2].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFruitImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {fruitImg4 && fruitDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={fruitDetails?.externalImageEntity[5].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFruitImg4(false)}
              />
            </div>
            {/* <div className='img-name'>
            <h4 className='img-describtion'>بوابة المشروع</h4>
          </div> */}
          </div>
        </div>
      )}

      {/* Flowers */}
      {flowerImg1 && flowerDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={flowerDetails?.externalImageEntity[10].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFlowerImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
            <h4 className='img-describtion'>بوابة المشروع</h4>
          </div> */}
          </div>
        </div>
      )}
      {flowerImg2 && flowerDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={flowerDetails?.externalImageEntity[14].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFlowerImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
            <h4 className='img-describtion'>بوابة المشروع</h4>
          </div> */}
          </div>
        </div>
      )}
      {flowerImg3 && flowerDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={flowerDetails?.externalImageEntity[11].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFlowerImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {flowerImg4 && flowerDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={flowerDetails?.externalImageEntity[6].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFlowerImg4(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {/* Coffe */}
      {coffeImg1 && coffeDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={coffeDetails?.externalImageEntity[10].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setCoffeImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {coffeImg2 && coffeDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={coffeDetails?.externalImageEntity[12].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setCoffeImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {coffeImg3 && coffeDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={coffeDetails?.externalImageEntity[5].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setCoffeImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {coffeImg4 && coffeDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={coffeDetails?.externalImageEntity[15].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setCoffeImg4(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}

      {/* Honey */}
      {honeyImg1 && honeyDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={honeyDetails?.externalImageEntity[4].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setHoneyImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {honeyImg2 && honeyDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={honeyDetails?.externalImageEntity[1].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setHoneyImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {honeyImg3 && honeyDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={honeyDetails?.externalImageEntity[6].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setHoneyImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {honeyImg4 && honeyDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={honeyDetails?.externalImageEntity[5].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setHoneyImg4(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}

      {/* Animal */}
      {animalImg1 && animalDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={animalDetails?.externalImageEntity[4].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setAnimalImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {animalImg2 && animalDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={animalDetails?.externalImageEntity[1].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setAnimalImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {animalImg3 && animalDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={animalDetails?.externalImageEntity[3].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setAnimalImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {animalImg4 && animalDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={animalDetails?.externalImageEntity[5].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setAnimalImg4(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}

      {/* Fish */}
      {fishImg1 && fishDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={fishDetails?.internalImageEntity[0].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFishImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {fishImg2 && fishDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={fishDetails?.internalImageEntity[2].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFishImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {fishImg3 && fishDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={fishDetails?.internalImageEntity[5].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFishImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {fishImg4 && fishDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={fishDetails?.internalImageEntity[3].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setFishImg4(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {/* Boat */}
      {boatImg1 && boatDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={boatDetails?.internalImageEntity[2].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setBoatImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {boatImg2 && boatDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={boatDetails?.internalImageEntity[1].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setBoatImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {boatImg3 && boatDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={boatDetails?.internalImageEntity[0].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setBoatImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {/* { boatImg4 && boatDetails && 
        <div className='modal-img-container'>
          <div className='img-location-marker-container'>
            <div className='img-container'>
              <img src={boatDetails?.internalImageEntity[3].path} alt='project-details' className='img-gate' />
              <button className='close-img-btn' onClick={() => setBoatImg4(false)} />
            </div>
            <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div>
          </div>
        </div>
      } */}

      {/* Crops */}
      {cropsImg1 && cropsDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={cropsDetails?.externalImageEntity[6].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setCropsImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {cropsImg2 && cropsDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={cropsDetails?.externalImageEntity[3].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setCropsImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {cropsImg3 && cropsDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={cropsDetails?.externalImageEntity[5].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setCropsImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {cropsImg4 && cropsDetails && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={cropsDetails?.externalImageEntity[4].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setCropsImg4(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {/* Super Market One*/}
      {superMarketOneImg1 && superMarketDetails1 && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={superMarketDetails1?.internalImageEntity[4].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setSuperMarketOneImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {superMarketOneImg2 && superMarketDetails1 && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={superMarketDetails1?.internalImageEntity[11].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setSuperMarketOneImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {superMarketOneImg3 && superMarketDetails1 && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={superMarketDetails1?.internalImageEntity[7].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setSuperMarketOneImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {superMarketOneImg4 && superMarketDetails1 && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={superMarketDetails1?.internalImageEntity[3].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setSuperMarketOneImg4(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {/* Super Market Two */}
      {superMarketTwoImg1 && superMarketDetails2 && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={superMarketDetails2?.internalImageEntity[3].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setSuperMarketTwoImg1(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {superMarketTwoImg2 && superMarketDetails2 && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={superMarketDetails2?.internalImageEntity[8].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setSuperMarketTwoImg2(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {superMarketTwoImg3 && superMarketDetails2 && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={superMarketDetails2?.internalImageEntity[0].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setSuperMarketTwoImg3(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
      {superMarketTwoImg4 && superMarketDetails2 && (
        <div className="modal-img-container">
          <div className="img-location-marker-container">
            <div className="img-container">
              <img
                src={superMarketDetails2?.internalImageEntity[11].path}
                alt="project-details"
                className="img-gate"
              />
              <button
                className="close-img-btn"
                onClick={() => setSuperMarketTwoImg4(false)}
              />
            </div>
            {/* <div className='img-name'>
              <h4 className='img-describtion'>بوابة المشروع</h4>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectMarkerLocations;
