import React, {useState, useEffect} from 'react'
import './DisplayVideo.css'
import axios from 'axios';
import firebase from '../../../firebase';
const DisplayVideo = ({selectedProject}) => {
  const [projectModelImg, setProjectModelImg] = useState()


  useEffect(()=>{
     firebase
    .database()
    .ref('projects')
    .on('value', snapshot => {
      const lists = snapshot.val();
      const arr = Object.keys(lists).map((k) => lists[k]);
      setProjectModelImg(arr.find(e => e.id == selectedProject.id))
      // addProjects(lists)

      
      // state(lists.find(e => e.id === id));
    })
    // axios
    // .get(`http://85.208.48.114:5000/project/${selectedProject.id}`)
    // .then(res => {

      //   setProjectModelImg(res.data)
    //  })
    // .catch(err => {
    //   console.log(err)
    // })



  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
//     <div className='display-video-container'>
//       {projectModelImg &&
//       <>
//       <div className='img-container'>
//           <img src={projectModelImg?.formImage} className='initial-img' alt='img' />
//         </div>
//         <div className='modal-video'>
//           <div className='btn-video'>
//             <button className='play-video' />
//           </div>
//         </div>
//       </>
//  }
//     </div>

<div 
className='video-container'
style={{position: 'absolute',
 right: '30%', top: '50%', 
 fontSize: '40px', width: '900px',
  height: '50vh'}}
//  style={{backgroundImage: `url("${flowerBackground}")`}}
>
جاري العمل علي الفيديو الخاص بنموذج المشروع ...
</div>
  )
}

export default DisplayVideo