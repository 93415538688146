import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import evara from "../../assets/png/Recovered-1.png";
import reef from "../../assets/png/slogo.png";
import ShowImgs from "./ShowImgs/ShowImgs";
import DisplayVideo from "./DisplayVideo/DisplayVideo";
import ExternalImg from "./InternalExternalImgs/ExternalImg";
import InternalImgs from "./InternalExternalImgs/InternalImgs";
import ProjectMarkerLocations from "../ProjectMarkerLoacations/ProjectMarkerLocations";
import "./ProjectSideMenu.css";
import firebase from "../../firebase";
import ShowProjectInfo from "./ShowProjectInfo/ShowProjectInfo";

const ProjectSideMenu = () => {
  const navigate = useNavigate();

  const param = useParams();

  const [displayDetails, setDisplayDetails] = useState(false);
  const [showImgs, setShowImgs] = useState(false);
  const [projectModule, setProjectModule] = useState(true);
  const [displayVideo, setDisplayVideo] = useState(false);
  const [exportImgs, setExportImgs] = useState(false);
  const [importImgs, setImportImgs] = useState(false);
  const [project, setProject] = useState();
  const [clickBtnBox, setClickBtnBox] = useState(true);
  const [clickBtnImgs, setClickBtnImgs] = useState(false);
  const [clickBtnVideo, setClickBtnVideo] = useState(false);
  const [clickBtnExport, setClickBtnExport] = useState(false);
  const [clickBtnImport, setClickBtnImport] = useState(false);
  const [showProjectInfo, setShowProjectInfo] = useState(false);
  const [clickBtnInfo, setClickBtnInfo] = useState(false);

  const handleBoxBtn = () => {
    setProjectModule(true);
    setShowImgs(false);
    setDisplayVideo(false);
    setExportImgs(false);
    setImportImgs(false);
    setClickBtnBox(true);
    setClickBtnImgs(false);
    setClickBtnVideo(false);
    setClickBtnExport(false);
    setClickBtnImport(false);
    setShowProjectInfo(false);
    setClickBtnInfo(false);
  };
  const handleImgsBtnClick = () => {
    setProjectModule(false);
    setShowImgs(true);
    setDisplayVideo(false);
    setExportImgs(false);
    setImportImgs(false);
    setClickBtnBox(false);
    setClickBtnImgs(true);
    setClickBtnVideo(false);
    setClickBtnExport(false);
    setClickBtnImport(false);
    setShowProjectInfo(false);
    setClickBtnInfo(false);
  };
  const handleVideoBtn = () => {
    setProjectModule(false);
    setShowImgs(false);
    setDisplayVideo(true);
    setExportImgs(false);
    setImportImgs(false);
    setClickBtnBox(false);
    setClickBtnImgs(false);
    setClickBtnVideo(true);
    setClickBtnExport(false);
    setClickBtnImport(false);
    setShowProjectInfo(false);
    setClickBtnInfo(false);
  };
  const handleExportBtn = () => {
    setProjectModule(false);
    setShowImgs(false);
    setDisplayVideo(false);
    setExportImgs(true);
    setImportImgs(false);
    setClickBtnBox(false);
    setClickBtnImgs(false);
    setClickBtnVideo(false);
    setClickBtnExport(true);
    setClickBtnImport(false);
    setShowProjectInfo(false);
    setClickBtnInfo(false);
  };
  const handleImportBtn = () => {
    setProjectModule(false);
    setShowImgs(false);
    setDisplayVideo(false);
    setExportImgs(false);
    setImportImgs(true);
    setClickBtnBox(false);
    setClickBtnImgs(false);
    setClickBtnVideo(false);
    setClickBtnExport(false);
    setClickBtnImport(true);
    setShowProjectInfo(false);
    setClickBtnInfo(false);
  };
  const handleInfoBtn = () => {
    setProjectModule(false);
    setShowImgs(false);
    setDisplayVideo(false);
    setExportImgs(false);
    setImportImgs(false);
    setClickBtnBox(false);
    setClickBtnImgs(false);
    setClickBtnVideo(false);
    setClickBtnExport(false);
    setClickBtnImport(false);
    setShowProjectInfo(true);
    setClickBtnInfo(true);
  };

  const id = param.id;

  useEffect(() => {
    firebase
      .database()
      .ref("projects")
      .on("value", (snapshot) => {
        let lists = snapshot.val();
        const arr = Object.keys(lists).map((k) => lists[k]);

        setProject(arr.find((e) => e.id == id));
      });
  }, []);

  // const addData = () => {

  //   firebase
  //     .database()
  //     .ref("الورد")
  //     .on("value", (snapshot) => {

  //       let lists = snapshot.val();
  //       const arr = Object.keys(lists).map((k) => lists[k]);

  //       arr.forEach((element) => {
  //         firebase
  //           .database()
  //           .ref("projects")
  //           .push(element)
  //           .then((res) => {

  //           });
  //       });

  //       // setProject(arr.find((e) => e.id == id));
  //     });
  // };
  return (
    <>
      {/* Side Menu */}

      <div className="project-model-side-menu">
        <div className="uper-btns">
          <div>
            <img className="reef-img" src={reef} alt="reef" />
            <hr className="side-menu-hr" />
          </div>
          <div className="icons-container">
            <Link to="/">
              <button className="home-icon" />
            </Link>

            {project?._slider && (
              <button
                className={
                  clickBtnInfo
                    ? "pi pi-info-circle project-info-clicked"
                    : "pi pi-info-circle project-info"
                }
                style={{ fontSize: "1.5rem" }}
                onClick={handleInfoBtn}
              />
            )}
          </div>
        </div>
        <hr className="side-menu-hr" />
        <div className="view-options">
          <button
            className={clickBtnBox ? "view-btn-box-clicked" : "view-btn-box"}
            onClick={handleBoxBtn}
          />
          <button
            className={clickBtnImgs ? "view-btn-imgs-clicked" : "view-btn-imgs"}
            onClick={handleImgsBtnClick}
          />
          <button
            className={
              clickBtnVideo ? "view-btn-video-clicked" : "view-btn-video"
            }
            onClick={handleVideoBtn}
          />
          <button
            className={
              clickBtnExport ? "view-btn-export-clicked" : "view-btn-export"
            }
            onClick={handleExportBtn}
          />
          <button
            className={
              clickBtnImport ? "view-btn-import-clicked" : "view-btn-import"
            }
            onClick={handleImportBtn}
          />
          {/* <button className={clickBtnImport ? 'view-btn-import-clicked' : 'view-btn-import'} onClick={() => navigate('/manage')} /> */}
        </div>
        <img className="evara-img" src={evara} alt="evara" />
      </div>
      {/* Show Project Details */}

      {/* <button style={{ fontSize: "1.5rem" }} onClick={addData}>
        test
      </button> */}

      {displayDetails && (
        <div className="custom-modal">
          <div className="project-details-container">
            <img className="project-img" src={project?.formImage} alt="img" />
            <div className="project-details">
              <h2 className="project-initiatorName">
                {project?.initiatorName}
              </h2>
              <div className="location">
                <button className="location-icon" />
                <h4 className="location-name">{project?.city}</h4>
              </div>
            </div>
            <button
              className="close-btn"
              onClick={() => setDisplayDetails(false)}
            >
              إغلاق
            </button>
          </div>
        </div>
      )}

      {/* Location Markers On Project */}
      {projectModule && project && (
        <ProjectMarkerLocations
          sectorName={project && project.sectorName}
          projectId={project && project.id}
          projectName={project && project.initiatorName}
        />
      )}

      {/* Show Project Information */}
      {showProjectInfo && <ShowProjectInfo selectedProject={project} />}

      {/* Side Menu Btns */}
      {showImgs && <ShowImgs selectedProject={project && project} />}
      {displayVideo && <DisplayVideo selectedProject={project && project} />}
      {exportImgs && <ExternalImg selectedProject={project && project} />}
      {importImgs && <InternalImgs selectedProject={project && project} />}
    </>
  );
};

export default ProjectSideMenu;
