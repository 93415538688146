import React, { useState, useEffect } from "react";
// import Carousel from 'react-bootstrap/Carousel';
import Carousel from "react-elastic-carousel";

import "../ShowImgs/ShowImgs.css";
import firebase from "../../../firebase";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const ShowProjectInfo = ({ selectedProject }) => {
  const imgs_path = [];
  const [projectData, setProjectData] = useState();
  const [images, setImages] = useState([]);

  const getProjectDetails = async (sectorName, id) => {
    firebase
      .database()
      .ref(sectorName)
      .orderByChild("id")
      .equalTo(id)
      // .on('child_added', obj => {
      //   obj.child('_slider')
      // })

      .on("value", (imgs) => {
        let arr = imgs.val();
        if (arr) {
          let sliderImgs = Object.keys(arr).map((k) => arr[k]);

          if (sliderImgs[0]._slider) {
            const silder = Object.keys(sliderImgs[0]._slider).map(
              (k) => sliderImgs[0]._slider[k]
            );

            setImages(silder);
          }
        }
        return;
      });
    // await firebase
    // .firestore()
    // .collection('projects')
    // .onSnapshot(snapshot => {
    //   const lists = snapshot.docs.map(doc => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }))

    //   setProjectData(lists.find(e => e.id == selectedProject.id))
    //   // addProjects(lists)

    
    //   // state(lists.find(e => e.id === id));
    // })
  };
  useEffect(() => {
    if (selectedProject.sectorName === "الفاكهة") {
      getProjectDetails(selectedProject.sectorName, 41);
    } else if (selectedProject.sectorName === "الورد") {
      getProjectDetails(selectedProject.sectorName, 55);
    } else if (selectedProject.sectorName === "البن") {
      getProjectDetails(selectedProject.sectorName, 34);
    } else if (selectedProject.sectorName === "العسل") {
      getProjectDetails(selectedProject.sectorName, 26);
    } else if (selectedProject.sectorName === "الثروة الحيوانية") {
      getProjectDetails(selectedProject.sectorName, 71);
    } else if (selectedProject.sectorName === "السمك") {
      getProjectDetails(selectedProject.sectorName, 45);
    } else if (selectedProject.sectorName === "المحاصيل البعلية") {
      getProjectDetails(selectedProject.sectorName, 32);
    } else if (selectedProject.sectorName === "القيمة المضافة") {
      getProjectDetails(selectedProject.sectorName, 65);
    }

    getProjectDetails();
  }, []);

  // eslint-disable-next-line array-callback-return
  // projectData && projectData?.externalImageEntity.map((element) => {
  //     imgs_path.push(element.path)})
  //   // eslint-disable-next-line array-callback-return
  //   && projectData?.internalImageEntity.map((element) => {
  //     imgs_path.push(element.path)})

  return (
    <div className="show-imgs-container">


      {/* <Carousel pause='hover' slide={false}>
        {images?.map((img_path, i) => {

          return <Carousel.Item interval={3000} key={i} >
            <img
              className="d-block w-100 show-img"
              src={img_path}
              alt="First slide"
            />
          </Carousel.Item>
        })}
      </Carousel> */}

      {images.length >= 0 && (
        <Carousel style={{ margin: 0 }}>
          {images?.map((item, i) => (
            <div key={i}>
              <img className="show-img" src={item} alt="First slide" />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default ShowProjectInfo;
