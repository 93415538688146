import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBm8abTyxAuDqjtM_NefJOkjKRiujVeLcE",
  authDomain: "evara-44379.firebaseapp.com",
  databaseURL: "https://evara-3d.firebaseio.com/",
  projectId: "evara-44379",
  storageBucket: "evara-44379.appspot.com",
  messagingSenderId: "116675819706",
  appId: "1:116675819706:web:e9219625d9e1ce0c592a83",
  measurementId: "G-M6CZ1RQYQ8",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
export default firebase;
