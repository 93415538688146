import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CollectionsIcon from "@mui/icons-material/Collections";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect } from "react";
import firebase from "../firebase";
import { useState } from "react";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ManageSlider() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get("email"),
    //   password: data.get("password"),
    // });
  };

  const [account, setAccount] = useState("");
  const [project, setProject] = useState("");

  const [accountList, setAccountList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  //   const [selectedFile, setSelectedFile] = useState()
  //   const [preview, setPreview] = useState()

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const [complete, setComplete] = useState(true);
  const [progress, setProgress] = useState(0);

  const [arrState, setArrState] = useState("");

  const handleSelect = (event, state, type) => {
    if (type === "account") {
      state(event);
      getProjects(event);
    } else if (type === "project") {
      state(event);
      getImages();
    } else {
      return;
    }
  };

  const getProjects = (sector) => {
    firebase
      .database()
      .ref(sector)
      .on("value", (res) => {
        const e = res.val();
        const arr = Object.keys(e).map((k) => e[k]);
        setProjectList(arr);

        // setProjectList(res.val())
      });
  };

  const selectImages = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      // setSelectedFile(undefined)
      setSelectedFiles(undefined);
      return;
    }

    setSelectedFiles(Array.from(e.target.files));
    // I've kept this example simple by using the first image instead of multiple
    // setSelectedFile(e.target.files[0]);
  };

  // main example >>>
  //   useEffect(() => {
  //     if (!selectedFile) {
  //         setPreview(undefined)
  //         return
  //     }

  //     const objectUrl = URL.createObjectURL(selectedFile)
  //     setPreview(objectUrl);

  //     // free memory when ever this component is unmounted
  //     return () => URL.revokeObjectURL(objectUrl)
  // }, [selectedFile])

  const uploadImages = () => {
    setComplete(false);
    // let arrayOfURLS = [];
    selectedFiles.map((e) => {
      const filePath = `${account}/slider/${e.name}`;
      const storageRef = firebase.storage().ref(filePath);
      const uploadTask = storageRef.put(e);
      uploadTask.on(
        "state_changed",
        (res) => {
          var percent = (res.bytesTransferred / res.totalBytes) * 100;
          setProgress(percent);
        },
        (e) => {
          console.log("error while uploading images", e);
        },
        (complete) => {
          storageRef.getDownloadURL().then((url) => {
            firebase
              .database()
              .ref(account)
              .orderByChild("initiatorName")
              .equalTo(project)
              .once("child_added", (snapshot) => {
                snapshot.child("_slider").ref.push(url);

                const mainProject = projectList.find(
                  (e) => e.initiatorName == project
                );

                firebase
                  .database()
                  .ref("projects")
                  .orderByChild("id")
                  .equalTo(mainProject.id)
                  .once("child_added", (snapshot) => {
                    snapshot.child("_slider").ref.push(url);
                  });
              });
            setComplete(true);
          });
          // firebase.database().ref(`${account}/slider/`).whe.push(url)
        }
      );
    });
  };
  useEffect(() => {
    if (!selectedFiles) {
      setPreviews(undefined);
      return;
    }
    let array = [];
    selectedFiles?.map((e) => {
      const objectUrl = URL.createObjectURL(e);
      array.push(objectUrl);

      setPreviews(array);
    });

    // const objectUrl = URL.createObjectURL(selectedFile)
    // setPreview(objectUrl);

    // // free memory when ever this component is unmounted
    // return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFiles]);

  useEffect(() => {
    firebase
      .database()
      .ref()
      .on("value", (res) => {
        const keys = Object.keys(res.val());
        keys.shift();
        keys.shift();

        setAccountList(keys);
      });
  }, [account, project]);

  // get images for project
  const getImages = () => {
    firebase
      .database()
      .ref(account)
      .orderByChild("initiatorName")
      .equalTo(project)
      .on("value", (snapshot) => {
        const e = snapshot.val();
        const arr = Object.keys(e).map((k) => e[k]);

        setArrState(arr);

        // snapshot.child("_slider").ref.push(url);
      });
  };
  // console.log('function test', getImages)

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <CollectionsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Manage Slider
          </Typography>
          {!complete && <CircularProgress />}
          <Box sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Account
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) =>
                  handleSelect(e.target.value, setAccount, "account")
                }
                label="Account"
                value={account}
              >
                {accountList.map((e, i) => (
                  <MenuItem key={i} value={e}>
                    {e}
                  </MenuItem>
                ))}
                {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "4%" }}>
              <InputLabel id="demo-simple-select-label">
                Select Project
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  return handleSelect(e.target.value, setProject, "project");
                }}
                label="Project"
                value={project}
              >
                {projectList.map((e, i) => (
                  <MenuItem key={i} value={e.initiatorName}>
                    {e.initiatorName} ({e.city})
                  </MenuItem>
                ))}
                {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "4%" }}>
              <input
                multiple
                type="file"
                label="choose Images"
                variant="outlined"
                onChange={(e) => selectImages(e)}
              />
            </FormControl>
            {/* {selectedFile &&  <img src={preview} /> } */}
            {
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {previews.map((e, i) => {
                  return (
                    <>
                      <ListItem key={i}>
                        <img
                          src={e}
                          srcSet={e}
                          alt={"sara"}
                          loading="lazy"
                          width={"100"}
                        />
                        <ListItemText primary={account} secondary={project} />
                      </ListItem>
                      <LinearProgress variant="determinate" value={progress} />
                    </>
                  );
                })}
              </List>
            }

            <Button
              type="button"
              fullWidth
              variant="contained"
              onClick={uploadImages}
              sx={{ mt: 3, mb: 2 }}
            >
              upload
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
