import React, { useState, useEffect } from 'react'
// import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios'
import '../ShowImgs/ShowImgs.css'
import firebase from '../../../firebase';
import Carousel from "react-elastic-carousel";
// import Item from "./Item";
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css'

const InternalImgs = ({selectedProject}) => {
  const internalImageArr = []
  const[dataProject, setDataProject] = useState()

  useEffect(() => {
    firebase
    .database()
    .ref('projects')
    .on('value', snapshot => {
      const lists = snapshot.val();
      const arr = Object.keys(lists).map((k) => lists[k]);
      setDataProject(arr.find(e => e.id === selectedProject.id))
      // addProjects(lists)

      
      // state(lists.find(e => e.id === id));
    })
  // axios
  // .get(`http://85.208.48.114:5000/project/${selectedProject.id}`)
  // .then(res => {
  //   setDataProject(res.data)
  // })
  // .catch(err => {
  //   console.log(err)
  // })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  dataProject?.internalImageEntity && 
  // eslint-disable-next-line array-callback-return
  dataProject?.internalImageEntity?.map((element) => {
  internalImageArr.push(element.path)})

  return (

    <>
    {
      internalImageArr?.length === 0 ? (
        <div 
        className='project-model-location'
        style={{position: 'absolute', right: '40%', top: '50%', fontSize: '40px', width: '900px', height: '50vh'}}
        //  style={{backgroundImage: `url("${flowerBackground}")`}}
        >
          جاري العمل علي التصميم ...
        </div> 
      ) : (
        <div className='show-imgs-container'>
        {/* <Carousel pause='hover'  >
    {internalImageArr?.map((imgPath, i) => {
      return (
        <Carousel.Item interval={1000} key={i} >
          <img
            className="d-block w-100 show-img"
            src={imgPath}
            alt="First slide"
          />
      </Carousel.Item>
      )
    })}
  </Carousel> */}



  <Carousel style={{margin:0}}>
    {internalImageArr?.map((item, i) => (
      <div key={i}>
           <img
                className="show-img"
                src={item}
                alt="First slide"
              />
        </div>
    ))}
  </Carousel> 
    {/* <Slide>
         {internalImageArr?.map((item, i)=> (
     
     <div key={i}>
     <img
          className="show-img"
          src={item}
          alt="First slide"
        />
  </div>
          ))} 
        </Slide> */}

</div>
      )
    }
    
    </>


   
  )
}

export default InternalImgs